
.cell {
    position: absolute;
    border-left: 1px solid gray;
    border-top: 1px solid gray;
    z-index: auto;
}

.cell.is-right-cell {
    border-right: 1px solid gray;
}

.cell.is-bottom-cell {
    border-bottom: 1px solid gray;
}

.inner-cell {
    width: 70%;
    height: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: 12px;
}


.cell.main-cell {
    background-color: #ffffffff;
}

.cell.main-cell:hover {
    background-color: #fff2cbff !important;
}

.cell.index-cell {
    background-color: #e9eaecff;
}

.cell.header-cell {
    background-color: #e9eaecff;
}

.cell.header-factor-cell {
    background-color: #d6f3d6ff;
}